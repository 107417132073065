.hide {
    opacity: 0;
    visibility: hidden
}

.remove {
    display: none!important
}

.blur {
    filter: blur(12px)
}

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.loading-init {
    width: 100%;
    align-self: center;
    text-align: center;
    text-transform: uppercase
}

.loading-init__header {
    font-size: 2.2em
}

.loading-init__status {
    margin-top: 1em;
    font-size: .8em;
    opacity: .75
}

.stage-container {
    overflow: hidden;
    box-sizing: initial;
    border: 1px solid #222;
    margin: -1px
}

@media(max-width: 840px) {
    .stage-container {
        border:0;
        margin: 0
    }
}

.canvas-container {
    width: 100%;
    height: 100%;
    transition: filter .3s
}

.canvas-container canvas {
    position: absolute;
    mix-blend-mode: lighten;
    transform: translateZ(0)
}

.controls {
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    transition: opacity .3s,visibility .3s
}

@media(min-width: 840px) {
    .controls {
        visibility:visible
    }

    .controls.hide:hover {
        opacity: 1
    }
}

.menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.42);
    transition: opacity .3s,visibility .3s
}

.menu__inner-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity .3s
}

.menu__header {
    margin-top: auto;
    margin-bottom: 8px;
    padding-top: 16px;
    font-size: 2em;
    text-transform: uppercase
}

.menu__subheader {
    margin-bottom: auto;
    padding-bottom: 12px;
    font-size: .86em;
    opacity: .8
}

.menu form {
    width: 100%;
    max-width: 400px;
    padding: 0 10px;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.menu .form-option {
    display: flex;
    align-items: center;
    margin: 16px 0;
    transition: opacity .3s
}

.menu .form-option label {
    display: block;
    width: 50%;
    padding-right: 12px;
    text-align: right;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.menu .form-option--select select {
    display: block;
    width: 50%;
    height: 30px;
    font-size: 1rem;
    font-family: "Russo One",arial,sans-serif;
    color: rgba(255,255,255,0.5);
    letter-spacing: .06em;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.5)
}

.menu .form-option--select select option {
    background-color: black
}

.menu .form-option--checkbox input {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0;
    opacity: .5
}

@media(max-width: 840px) {
    .menu .form-option select,.menu .form-option input {
        outline:0
    }
}

.close-menu-btn {
    position: absolute;
    top: 0;
    right: 0
}

.btn {
    opacity: .16;
    width: 50px;
    height: 50px;
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    transition: opacity .3s
}

.btn--bright {
    opacity: .5
}

@media(min-width: 840px) {
    .btn:hover {
        opacity:.32
    }

    .btn--bright:hover {
        opacity: .75
    }
}

.btn svg {
    display: block;
    margin: auto
}

.credits {
    margin-top: auto;
    margin-bottom: 10px;
    padding-top: 6px;
    font-size: .8em;
    opacity: .75
}

.credits a {
    color: rgba(255,255,255,0.5);
    text-decoration: none
}

.credits a:hover,.credits a:active {
    color: rgba(255,255,255,0.75);
    text-decoration: underline
}

.help-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    transition-property: visibility;
    transition-duration: .25s
}

.help-modal__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-in;
    transition-duration: .25s
}

.help-modal__dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    max-height: calc(100vh - 100px);
    margin: 10px;
    padding: 20px;
    border-radius: .3em;
    background-color: rgba(0,0,0,0.4);
    opacity: 0;
    transform: scale(0.9,0.9);
    transition-property: opacity,transform;
    transition-timing-function: ease-in;
    transition-duration: .25s
}

@media(min-width: 840px) {
    .help-modal__dialog {
        font-size:1.25rem;
        max-width: 500px
    }
}

.help-modal__header {
    font-size: 1.75em;
    text-transform: uppercase;
    text-align: center
}

.help-modal__body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 1em 0;
    padding: 1em 0;
    border-top: 1px solid rgba(255,255,255,0.25);
    border-bottom: 1px solid rgba(255,255,255,0.25);
    line-height: 1.5;
    color: rgba(255,255,255,0.75)
}

.help-modal__close-btn {
    flex-shrink: 0;
    outline: 0;
    border: 0;
    border-radius: 2px;
    padding: .25em .75em;
    margin-top: .36em;
    font-family: "Russo One",arial,sans-serif;
    font-size: 1em;
    color: rgba(255,255,255,0.5);
    text-transform: uppercase;
    letter-spacing: .06em;
    background-color: rgba(255,255,255,0.25);
    transition: color .3s,background-color .3s
}

.help-modal__close-btn:hover,.help-modal__close-btn:active,.help-modal__close-btn:focus {
    color: #FFF;
    background-color: #09F
}

.help-modal.active {
    visibility: visible;
    transition-duration: .4s
}

.help-modal.active .help-modal__overlay {
    opacity: 1;
    transition-timing-function: ease-out;
    transition-duration: .4s
}

.help-modal.active .help-modal__dialog {
    opacity: 1;
    transform: scale(1,1);
    transition-timing-function: ease-out;
    transition-duration: .4s
}