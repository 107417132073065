* {
  position: relative;
  box-sizing: border-box
}

html,body {
  height: 100%;
  margin:0;
  padding:0;
}


body {
  overflow: hidden;
  color: rgba(255,255,255,0.5);
  font-family: "Russo One",arial,sans-serif;
  line-height: 1.25;
  letter-spacing: .06em
}