.home{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#000;
    min-height: 80vh;
}
.home_button{
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align: center;
    border: 0;
    background-size: 300% 100%;
    border-radius: 50px;
    line-height: 55px;

    background-image: linear-gradient(to right,#ed6ea0,#ec8c69,#f7186a,#FBB03B);
    box-shadow: 0 4px 15px 0 rgb(236 116 149 / 75%);
}
.home_button:hover{
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}